const siteAssets = {
    ssmfi: {
        title: "Shepherd's Staff",
        logo: require('../assets/images/ss/logo.png'),
        favicon: require('../assets/images/ss/favicon.jpg'),
        background: require('../assets/images/ss/background.jpg'),
    },
    kinetix: {
        title: "Kinetix",
        logo: require('../assets/images/kinetix/logo.png'),
        background: require('../assets/images/kinetix/background.jpg'),
    }
};

const siteTerms = {
    fallback: {
        field_worker: "Field Worker",
        business_details: "Business Details",
        business: "Business",
        working: "Working",
        locale: "KG"
    },
    kinetix: {
        field_worker: "Field Worker",
        business_details: "Business Details",
        business: "Business",
        working: "Working",
        locale: "KG"
    },
    ssmfi: {
        field_worker: "Missionary",
        business_details: "Mission Details",
        business: "Ministry",
        working: "Ministering",
        locale: "SSMFI"
    }
}

const availableSites = Object.keys(siteAssets);

const helpers = {

    getSite: function() {
        const url = window.location.host;
        return url;
    },

    getAsset: function(asset) {
        const { REACT_APP_SITE: site = 'ssmfi' } = process.env;

        if (!site || !availableSites.includes(site)) throw `Unknown site: "${site}"`;
        return siteAssets[site][asset];
    },

    getTerm: function(term) {
        const { REACT_APP_SITE: site = 'ssmfi' } = process.env;
        if (!site || !availableSites.includes(site)) throw `Unknown site: "${site}"`;
        return siteTerms[site][term];
    },

    getTerms: function() {
        const { REACT_APP_SITE: site = 'ssmfi' } = process.env;

        if (!site || !availableSites.includes(site)) throw `Unknown site: "${site}"`;
        return siteTerms[site];
    }
}

export default helpers;
